.wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.stats {
    width: 200px;
    border: 1px solid #ccc;
    padding: 10px;
    font-weight: bold;
}

.success {
    color: #00974c;
}

.failed {
    color: red;
}

.pending {
    color: blue;
}

.mail {
    flex: 1;
    border: 1px solid #ccc;
    border-left: none;
}

.subject {
    border-bottom: 1px solid #ccc;
    font-weight: bold;
    padding: 10px;
}

.body {
    padding: 10px;
}

.body_heading {
    font-weight: bold;
    padding-bottom: 10px;
}

.body_content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    line-clamp: 6;
    -webkit-box-orient: vertical;
}

.dt {
    padding: 5px 10px;
    font-style: italic;
    border-top: 1px solid #ccc;
    color: #666;
}
