.wrapper {
    box-sizing: border-box;
    background-color: white;
    height: 90vh;
    border: 1px solid rgba(0, 0, 0, 0.158);
    padding: 12px;
    border-radius: 10px;
    box-shadow: 2px 2px 9px -1px rgba(204, 204, 204, 1);
    position: relative;
}

.photo {
    border: 1px solid rgba(0, 0, 0, 0.26);
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}

.name {
    color: black;
    margin-top: 7px;
    font-size: 1em;
    margin-bottom: 2px;
}

.section {
    padding: 10px 0;
}
.section p {
    margin: 0;
    font-size: 0.95em;
}

.number {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 5px 10px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    /* border: 1px solid red; */
    font-weight: bold;
    font-size: 1.2em;
}

.br_table,
.br_table td {
    border: 1px solid #aaa;
    border-collapse: collapse;
    padding: 1px 10px;
}

.highlight_table td {
    vertical-align: top;
    padding-top: 2px;
}

.partner_exp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
}
