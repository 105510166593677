.wrapper {
    /* background-color: white; */
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.226);
    padding: 10px;
    position: relative;
}

.grid_wrapper {
    display: grid;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 10px;
}

.page_no {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(255, 255, 190);
    padding: 3px 6px;
    border-radius: 3px;
}

.website {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgb(255, 255, 190);
    padding: 3px 6px;
    border-radius: 3px;
    font-style: italic;
}

.other_info {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgb(255, 255, 190);
    padding: 3px 6px;
    border-radius: 3px;
}
