.wrapper {
}

.grid_wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.item_wrapper {
  border: 1px solid #ccc;
}

.item {
  padding: 12px;

  display: flex;
  flex-direction: row;
  gap: 10px;
}

.item img {
  max-width: 300px;
  height: 120px;
  object-fit: contain;
}

.item .status {
  border-radius: 3px;
  background-color: #95d895;

  padding: 2px 6px;
}

.action_wrapper {
  background-color: #f3f3f3;
  padding: 12px;
  display: flex;
  gap: 3px;
  justify-content: flex-start;
}

.item p {
  margin-bottom: 5px;
}
