.wrapper {
    border: 1px solid rgba(206, 206, 206, 0.2);
    box-shadow: rgba(155, 155, 155, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    /* width: 22rem; */
    max-width: 25rem;
    height: auto;
    position: relative;
    cursor: pointer;
}

.fav_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.profile_pic {
    width: 100%;
    border-radius: 30%;
}

.name {
    text-align: center;
    text-transform: capitalize;
    margin-top: 10px;
}

.one_line {
    max-width: 50ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.action_bar {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        max-width: 100%;
    }
}
