.grand {
    border: 1px solid rgba(99, 99, 99, 0.2);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1em;
    /* width: 22rem; */
    max-width: 25rem;
    height: auto;
}

.word-limit {
    max-width: 50ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media screen and (max-width: 600px) {
    .grand {
        padding: 2em;
        max-width: 100%;
    }
}
