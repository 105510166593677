.bg_old {
  --s: 50px; /* control the size */
  --c1: #fff0e5;
  --c2: #88d7ff;
  --c3: #ffb18d;

  --_g: 50%, #0000 37%, var(--c1) 39% 70%, #0000 72%;
  --_t: 50%, var(--c2) 40deg, var(--c3) 0 140deg, var(--c2) 0 180deg, #0000 0;
  --_s: 47% 50% at;
  background: radial-gradient(var(--_s) -10% var(--_g)) 0 calc(var(--s) / 2),
    radial-gradient(var(--_s) -10% var(--_g)) calc(var(--s) / 2) 0,
    radial-gradient(var(--_s) 110% var(--_g)),
    radial-gradient(var(--_s) 110% var(--_g)) calc(var(--s) / 2)
      calc(var(--s) / 2),
    conic-gradient(from 0deg at 55% var(--_t)) calc(var(--s) / 4) 0,
    conic-gradient(from 180deg at 45% var(--_t)) calc(var(--s) / 4) 0, var(--c2);
  background-size: var(--s) var(--s);
}

.bg {
  --s: 24px; /* control the size*/
  --c1: rgb(242, 242, 242);
  --c2: #cdcccb;
  --c3: #999999;

  --_g: 0 120deg, #0000 0;
  background: conic-gradient(
      from 0deg at calc(500% / 6) calc(100% / 3),
      var(--c3) var(--_g)
    ),
    conic-gradient(
      from -120deg at calc(100% / 6) calc(100% / 3),
      var(--c2) var(--_g)
    ),
    conic-gradient(
      from 120deg at calc(100% / 3) calc(500% / 6),
      var(--c1) var(--_g)
    ),
    conic-gradient(
      from 120deg at calc(200% / 3) calc(500% / 6),
      var(--c1) var(--_g)
    ),
    conic-gradient(
      from -180deg at calc(100% / 3) 50%,
      var(--c2) 60deg,
      var(--c1) var(--_g)
    ),
    conic-gradient(
      from 60deg at calc(200% / 3) 50%,
      var(--c1) 60deg,
      var(--c3) var(--_g)
    ),
    conic-gradient(
      from -60deg at 50% calc(100% / 3),
      var(--c1) 120deg,
      var(--c2) 0 240deg,
      var(--c3) 0
    );
  background-size: calc(var(--s) * 1.732) var(--s);
}

.bg_new {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: repeating-radial-gradient(
      circle at 0 0,
      transparent 0,
      #e5e5f7 10px
    ),
    repeating-linear-gradient(#444cf755, #444cf7);
}

/* https://css-pattern.com/ */
