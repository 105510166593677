.wrapper {
    width: 100%;
    /* background-color: rgb(184, 1, 1); */
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 10;
    box-shadow: 0px 3px 10px 3px rgba(5, 5, 5, 0.05);
}

.wrapper_mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sidebar {
    position: fixed;
    z-index: 11;
    height: 100vh;
    width: 60%;
    background-color: white;
    transition: 0.2s;
    left: -40%;
    box-shadow: 3px 0px 10px 3px rgba(5, 5, 5, 0.05);
    padding: 15px;
}
