.wrapper {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    min-height: 90vh;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    margin: 10px 0;
}

.title_wrapper i {
    font-size: 1.6rem;
    color: gray;
    margin-top: -10px;
}

.title {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.action_wrapper {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.main {
    padding: 10px 0;
}

@media only screen and (max-width: 767px) {
    .wrapper {
        padding: 15px;
    }
    .header {
        flex-direction: column;
        /* margin: 0 -10px; */
        align-items: stretch;
    }
    .action_wrapper {
        flex-wrap: wrap;
        gap: 5px;
        justify-content: flex-start;
        align-items: flex-start;
        /* border: 1px dashed purple; */
    }
}
