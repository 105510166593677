.wrapper {
    width: 100%;
    height: 100vh;
    border: 2px solid black;

    display: flex;
    flex-direction: row;
    gap: 0;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    font-family: Roboto, sans-serif;
}

.box_left {
    width: 40%;
    background-color: rgb(206, 221, 221);
    height: 100%;
    padding: 10px 10px 4% 10px;
}

.box_right {
    flex: 1;
    background-color: white;
    padding: 15px 25px;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

.name {
    /* font-size: 3em; */
    font-size: 2.2em;
    /* font-family: "Domine", serif; */
    /* font-family: "Signika Negative", sans-serif; */
}

.main_photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.serial_number {
    position: absolute;
    top: -10px;
    right: 10px;
    background-color: #faf68e;
    padding: 7px 14px;
    font-size: 3.5em;
    font-weight: bold;

    border-radius: 10px;
}

.highlight_table td {
    vertical-align: top;
    font-size: 1.8em;
    line-height: 1.2em;
    padding-top: 5px;
    padding-right: 20px;
}

.highlight_table .label,
.br_table .label {
    color: #888;
    white-space: nowrap;
}

.br_table {
    margin-top: 10px;
    margin-bottom: 5px;
}

.br_table,
.br_table td {
    border: 1px solid #aaa;
    border-collapse: collapse;
    padding: 5px 10px;
    font-size: 1.2em;
    line-height: 1.3em;
}

/* ========================================= */
.bg_male {
    --s: 80px; /* control the size */
    --c: rgb(105, 173, 218);

    --_g: #0000 calc(-650% / 13) calc(50% / 13), var(--c) 0 calc(100% / 13),
        #0000 0 calc(150% / 13), var(--c) 0 calc(200% / 13),
        #0000 0 calc(250% / 13), var(--c) 0 calc(300% / 13);
    --_g0: repeating-linear-gradient(45deg, var(--_g));
    --_g1: repeating-linear-gradient(-45deg, var(--_g));
    background: var(--_g0), var(--_g0) var(--s) var(--s), var(--_g1),
        var(--_g1) var(--s) var(--s) #c02942;
    background-size: calc(2 * var(--s)) calc(2 * var(--s));
}
.bg_female {
    --s: 80px; /* control the size */
    --c: rgb(219, 106, 149);

    --_g: #0000 calc(-650% / 13) calc(50% / 13), var(--c) 0 calc(100% / 13),
        #0000 0 calc(150% / 13), var(--c) 0 calc(200% / 13),
        #0000 0 calc(250% / 13), var(--c) 0 calc(300% / 13);
    --_g0: repeating-linear-gradient(45deg, var(--_g));
    --_g1: repeating-linear-gradient(-45deg, var(--_g));
    background: var(--_g0), var(--_g0) var(--s) var(--s), var(--_g1),
        var(--_g1) var(--s) var(--s) #c02942;
    background-size: calc(2 * var(--s)) calc(2 * var(--s));
}
