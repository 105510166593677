.page_wrapper {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  /* height: 100vh;
    min-height: 100vh;
    max-height: 100vh; */
  height: 93.33vh;
  width: 86.2vw;
  font-size: 0.9em;
  /* ===================== */
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.226);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  padding: 10px;
  padding-top: 7vh;
  position: relative;
}

.column {
  background: white;
  border-radius: 10px;
  box-shadow: 2px 2px 9px -1px rgba(204, 204, 204, 1);
  /* padding: 15px; */
}

.heading {
  font-weight: bold;
  color: white;
  background-color: blue;
  padding: 10px;
  border-radius: 10px 10px 0 0;
  text-align: center;
  text-transform: uppercase;
}

.content {
  padding: 15px;
  padding-top: 0;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  padding: 3px 4px;
  text-align: left;
  font-size: 0.95em;
}

.table th:nth-child(3),
.table td:nth-child(3) {
  text-align: right;
}

.table,
.table th,
.table td {
  border: none;
}

.max_lines_1 {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Clip overflowing text */
  text-overflow: ellipsis; /* Add an ellipsis (...) at the end if the text is clipped */
  max-width: 100px; /* Adjust the maximum width as needed */
}

/* ==================== */

.top_left_bar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
}

.title {
  padding: 10px 12px;
  background-color: blue;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  border-bottom-right-radius: 3px;
}

.logo {
  height: 40px;
  width: auto;
  object-fit: contain;
  background-color: white;
  padding: 3px 30px 3px 10px;
}

.page_no {
  background-color: rgb(255, 255, 190);
  border-bottom-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.5em;
}

@media print {
  .page_break {
    page-break-before: always;
  } /* page-break-after works, as well */
}
