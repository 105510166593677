.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 2px 2px 9px -1px rgba(204, 204, 204, 1);
}

.heading {
    margin-bottom: 30px;
}

.scroll_label {
    font-style: italic;
    color: #555;
    margin-top: 15px;
    font-size: 1.1em;
    margin-bottom: 3px;
}

@media print {
    .wrapper {
        display: none;
    }
}
