/* Signika Negatie */
@import url("https://fonts.googleapis.com/css2?family=Signika+Negative&family=Tangerine:wght@700&display=swap");
/* Domine */
@import url("https://fonts.googleapis.com/css2?family=Domine&family=Signika+Negative&family=Tangerine:wght@700&display=swap");
.wrapper {
    width: 100%;
    height: 100vh;

    font-family: "Signika Negative", sans-serif;
    /* font-family: "Tangerine", cursive; */
}

.input_bar {
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 40%;
    /* left: 50%;
    transform: translateX(-50%); */
    padding: 5px 10px;
    background-color: #faf68e;
    border-radius: 0 0 0 0;

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.logo {
    height: 40px;
}
