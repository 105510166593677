.page_wrapper {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  /* height: 100vh;
    min-height: 100vh;
    max-height: 100vh; */
  height: 93.33vh;
  width: 86.2vw;
  font-size: 0.9em;
  /* ===================== */
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.226);
  /* border: 1px solid blue; */
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 7vh;
}

.table {
  /* margin-top: 10px; */
  background-color: white;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.table .head td {
  padding-left: 5px;
  border: 1px solid rgba(0, 0, 0, 0.226);
}

.table .head td:first-child {
  padding-left: 5px;
}

.table .row {
  height: 10vh;
}

.table .row > td {
  border: 1px solid rgba(0, 0, 0, 0.226);
  vertical-align: top;
}

.col1 {
  width: 2%;
}

.col2 {
  width: 14%;
}

.col3 {
  width: 7%;
  max-width: 7%;
}

.col4 {
  width: 10%;
}

.col5 {
  width: 11%;
  padding-right: 2px;
}

.col6 {
  width: 10%;
}

.col7 {
  width: 15%;
}

.grid_wrapper {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
  grid-template-rows: repeat(6, minmax(0, 1fr));
  gap: 8px;
}

.website {
  position: absolute;
  bottom: 5px;
  left: 10px;
  background-color: rgb(255, 255, 190);
  padding: 3px 6px;
  border-radius: 3px;
  font-style: italic;
}

.legends {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 190);
  padding: 3px 6px;
  border-bottom-left-radius: 3px;
}

.top_left_bar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
}

.bottom_bar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 65%;
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 190);
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
}

.bottom_bar p {
  width: 100%;
  text-align: left;
  font-size: 0.7em;
  line-height: 1.2em;
}

.bottom_bar_left {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 190);
  padding: 2px;
  padding-left: 7px;
  padding-right: 7px;
  color: blue;
  font-style: italic;
}

.title {
  padding: 10px 12px;
  background-color: blue;
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  border-bottom-right-radius: 3px;
}

.logo {
  height: 40px;
  width: auto;
  object-fit: contain;
  background-color: white;
  padding: 3px 10px 3px 10px;
}

.page_no {
  background-color: rgb(255, 255, 190);
  border-bottom-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  font-size: 1.5em;
}

@media print {
  .page_break {
    page-break-before: always;
  } /* page-break-after works, as well */
}
