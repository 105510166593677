.wrapper {
    box-sizing: border-box;
    background-color: white;
    /* height: 45vh; */
    height: 42vh;
    border: 1px solid rgba(0, 0, 0, 0.158);
    /* padding: 12px; */
    padding: 8px;
    border-radius: 10px;
    box-shadow: 2px 2px 9px -1px rgba(204, 204, 204, 1);
    position: relative;
}

.top_part {
    display: flex;
    flex-direction: row;
    /* gap: 10px; */
    gap: 5px;
}

.photo {
    border: 1px solid rgba(0, 0, 0, 0.26);
    /* width: 120px;
    height: 120px; */
    width: 8em;
    height: 8em;
    border-radius: 10px;
    object-fit: cover;
}

.name {
    color: black;
    margin-top: 7px;
    font-size: 1em;
    margin-bottom: 2px;
}

.section {
    padding: 0px 0;
}
.section p {
    margin: 0;
    font-size: 0.95em;
}

.section_heading {
    padding-top: 5px;
}

.number {
    position: absolute;
    /* top: 3px; */
    bottom: 0px;
    background-color: rgb(255, 255, 190);
    /* background-color: red; */
    padding: 4px 8px;
    border-radius: 10px 0 10px 0;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    right: 0px;
    font-weight: bold;
    font-size: 1.2em;
}

.br_table {
    margin-top: 3px;
    margin-bottom: 5px;
}

.br_table,
.br_table td {
    border: 1px solid #aaa;
    border-collapse: collapse;
    padding: 0px 10px;
    font-size: 0.9em;
    line-height: 1.3em;
}

.highlight_table td {
    vertical-align: top;
    /* padding-top: 1x; */
    font-size: 0.9em;
    line-height: 1.3em;
}

.max_lines_2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.max_lines_3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.max_lines_1 {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Clip overflowing text */
    text-overflow: ellipsis; /* Add an ellipsis (...) at the end if the text is clipped */
    max-width: 100px; /* Adjust the maximum width as needed */
}

.max_lines_22 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 3em;
}
