.section {
    padding: 15px;
}

.section .heading {
    color: red;
    text-transform: uppercase;
}

.section p {
    margin-bottom: 5px;
}
