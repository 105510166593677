.flashText {
    animation: flash 0.4s infinite alternate;
}

@keyframes flash {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}
