.wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgb(243, 243, 243);
}

.main {
    max-width: 1080px;
    margin: 0 auto;
    background-color: white;
    min-height: 100%;
    /* padding-top: 10px; */
}
