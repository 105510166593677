.parent {
    display: flex;
}
.child1 {
    width: 25em;
    height: auto;
    background-color: rgb(241, 241, 3);
    margin: 0.7em 0.7em 0 0;
    border: 1px solid rgb(193, 193, 193);
}
@media screen and (max-width: 600px) {
    .parent {
        flex-wrap: wrap;
    }
    .child1 {
        width: 100%;
    }
    .user_div {
        margin: 0 0 0 0 !important;
        margin-top: 1em !important;
    }
    .btns1_div {
        display: flex;
        flex-wrap: wrap;
    }
    .child2_btns {
        padding: 8px 1em !important;
    }
    .btns2_div {
        justify-content: space-evenly !important;
    }
    .partner_expectation {
        width: fit-content !important;
    }
    .child2 {
        width: 100% !important;
    }
}

.partner_expectation {
    margin: 0 0 2em 0;
    width: inherit;
    font-family: Arial;
    line-height: 1.6;
    word-spacing: 1px;
    font-size: 16px;
}
.child2 {
    width: 45em;
    height: auto;
    background-color: rgb(255, 255, 255);
    margin: 0.7em 0 0 0;
}
.image_div1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
}
.image_div2 {
    width: 15em;
    height: 15em;
    /* background-color: brown; */
    border-radius: 50%;
    border: 2px solid brown;
}
.image {
    width: 15em;
    height: 15em;
    border-radius: 50%;
}
.info_div {
    justify-content: center;
    /* display: flex; */
    align-items: center;
    margin-left: 3em;
    margin-bottom: 1em;
    font-family: "Roboto", sans-serif;
}
.info_div2 {
    line-height: 10px;
}
.btns1_div {
    margin-left: 3em;
}
.child1_btns {
    margin: 0.4em 0;
    appearance: none;
    background-color: brown;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
        rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 10px 40px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.child1_btns:hover {
    background-color: rgb(199, 45, 45);
    text-decoration: none;
    transition-duration: 0.1s;
}

.child1_btns:disabled {
    background-color: #fafbfc;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959da5;
    cursor: default;
}

.child1_btns:active {
    background-color: #edeff2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.child1_btns:focus {
    outline: 1px transparent;
}

.child1_btns:before {
    display: none;
}

.child1_btns:-webkit-details-marker {
    display: none;
}
.btns2_div {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
}
.child2_btns {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue",
        Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: 10px 60px;
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.child2_btns:hover,
.child2_btns:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
}

.child2_btns:hover {
    transform: translateY(-1px);
}

.child2_btns:active {
    background-color: #ee3435;
    border-color: rgba(0, 0, 0, 0.15);
    color: white;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;

    transform: translateY(0);
}
.child2_info {
    width: 100%;
    height: auto;
    border: 1px solid #03030333;
    margin: 1em 0;
    padding: 15px;
}
.aboutme_text {
    font-size: 16px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
}
.gallery_text {
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    margin-top: 1em;
    /* justify-content: space-evenly; */
}
.gallery_images {
    width: 17em;
    height: 18em;
    margin: 1em;
}
.gallery_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.about_me_div {
    width: 100%;
    height: auto;
    border: 1px solid rgb(151, 151, 151);
    padding: 1em;
}
.user_personal_details {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
}
.user_div {
    margin: 0 0 0 auto;
}

.bold_div {
    font-weight: 1000;
}
.logo_div {
    margin-top: 1em;
}

.active-btn {
    background-color: #ee3435;
    color: #ffff !important;
}
