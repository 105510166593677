.wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}

.sidebar {
  width: 250px;
  /* height: 100vh; */
  height: auto;
  min-height: 100vh;
  /* border-right: 1px solid #e6e6e6; */
  padding: 0 10px;
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.action_wrapper {
  /* padding-left: 15px; */
}

.main {
  flex: 1;
  padding: 20px;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    border-right: none;
    padding: 0;
    margin-top: 0;
  }

  .main {
    padding: 0;
    padding-top: 20px;
  }
}
