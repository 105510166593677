.error {
    color: red;
}

.success {
    color: green;
}

.info {
    color: blue;
}

.msg {
    padding: 15px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
}

.msg p {
    margin-top: -3px;
}

.msg.error {
    color: rgb(212, 0, 0);
    border: 1px solid rgb(255, 191, 191);
    background-color: rgba(255, 214, 214, 0.308);
}

.msg.info {
    color: blue;
    border: 1px solid rgb(169, 169, 255);
    background-color: rgba(124, 124, 255, 0.096);
}

.msg.warn {
    color: rgb(255, 166, 0);
    border: 1px solid rgb(255, 232, 169);
    background-color: rgba(255, 242, 124, 0.185);
}

.msg.success {
    color: rgb(0, 139, 19);
    border: 1px solid rgba(216, 243, 207, 0.985);
    background-color: rgba(124, 255, 133, 0.185);
}

.img_add_block {
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 5px;
    border: 3px dashed #aaa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 0.9em;
    color: #aaa;
    cursor: pointer;
}

.img_add_block i {
    font-size: 1.5em;
}

.preview-img {
    width: 150px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.233);
    margin-top: 0.2em;
}

.img-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;
}

.img-group .preview_wrapper {
    height: 100px;
    min-width: 100px;
    width: auto;
}

.img-group .preview_wrapper .preview_img {
    height: 100px;
    width: auto;
    min-width: 100px;
    object-fit: contain;
}

.img-group .img_add_block {
    width: 100px;
    height: 100px;
    margin-top: 0.2em;
}

.preview_wrapper {
    width: 150px;
    max-width: 100%;
    height: 150px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.233);
    margin-top: 0.2em;
    position: relative;
    overflow: hidden;
}

.preview_img {
    height: 150px;
    width: auto;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}

.preview_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.452);
    opacity: 0;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.preview_overlay:hover {
    opacity: 1;
}

.preview_overlay i {
    font-size: 1.3em;
    cursor: pointer;
}
