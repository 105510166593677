.wrapper {
    padding-top: 25px;
    padding-bottom: 200px;
}

.header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
}

.main_photo {
    width: 340px;
    height: auto;
}

.name {
    font-weight: bold;
}

.table {
    border-collapse: collapse;
    font-size: 1.1em;
}

.table td {
    padding-bottom: 10px;
}

.table td {
    border: none;
}

.table tr:not(:last-child) {
    border-bottom: none;
}

.table .key {
    padding-right: 40px;
    color: #555;
    min-width: 225px;
}

.section {
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 20px;
}

.section_heading {
    font-size: 1.1em;
    color: red;
    text-transform: uppercase;
}

.action_wrapper {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.two_col_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.address_wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
    }

    .two_col_wrapper {
        grid-template-columns: 1fr;
    }

    .section {
        margin-left: -10px;
        margin-right: -10px;
        padding: 10px;
        width: calc(100%+20px);
    }

    .table .key {
        padding-right: 15px;
        color: #555;
        /* min-width: 225px; */
        min-width: 0;
        /* max-width: 20%; */
    }

    .main_photo {
        width: 100%;
    }

    .address_wrapper {
        flex-direction: column;
    }
}
